// Main.js

;(function ($, window, document, undefined) {

  // ########################################
  // # Define Functions
  // ########################################
  
  function func() {
    
  };

  function mobileMenu() {
    console.log('menu init');
    var menu = $('#mobilemenu'),
        body = $('body');

    menu.on('click', function() {
      if (body.hasClass('menu-active')) {
        body.removeClass('menu-active');
      } else {
        body.addClass('menu-active');
      }
    });
  }






  
  
  // ########################################
  // # Initialize all necessary event handlers
  // ########################################

  var init = function () {
    mobileMenu();
  };
  
  // ########################################
  // # Call init on Document Ready
  // ########################################

  $(init);
  
})(window.jQuery, this, this.document);
