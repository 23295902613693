;(function ($, window, document, undefined) {

  function test () {
    // $('body').css('background', '#00f');
  };

  var init = function () {
    test();
  };
  
  $(init);

})(window.jQuery, this, this.document);
